<template>
  <v-dialog v-model="dialog" max-width="800">
    <perseu-card title="Formulário de Fenômeno Vascular">
      <template slot="content">
        <v-form ref="form" @submit.prevent="save">
          <v-text-field
            label="Nome"
            v-model="vascularPhenomenon.name"
            :rules="[$rules.required]"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn color="secondary" @click="save">Salvar</v-btn>
      </template>
    </perseu-card>
  </v-dialog>
</template>

<script>
import * as service from "@/services/vascular-phenomena-service.js";

export default {
  data: () => ({
    vascularPhenomenon: {},
    dialog: false,
  }),
  methods: {
    async save() {
      try {
        if (!this.$refs.form.validate()) return;
        this.$store.dispatch("loading/openDialog");
        if (this.vascularPhenomenon.id) {
          const { data } = await service.update(this.vascularPhenomenon);
          this.$emit("updated", data);
        } else {
          const { data } = await service.create(this.vascularPhenomenon);
          this.$emit("created", data);
        }
        this.$toasted.global.defaultSuccess();
        this.dialog = false;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    edit(item) {
      this.vascularPhenomenon = item;
      this.dialog = true;
    },
    create() {
      this.vascularPhenomenon = {};
      this.dialog = true;
    },
  },
};
</script>

<style></style>
